import React, { Component } from 'react';
class Navbar extends Component {
    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-sm navbar-light fixed-top">
                <div className="container-fluid">    
                    <a className="navbar-brand" href="#home" id="contactTitle">
                    <img src="img/jzg-logo-text.png" alt="Jiaozi Group Logo" width='230' height='50' />
                    </a>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" 
                        aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item "><a className="nav-link" href="#invest"> INVEST <span className="sr-only">(current)</span></a></li>
                            <li className="nav-item "><a className="nav-link" href="#about"> ABOUT </a></li>
                            <li className="nav-item "><a className="nav-link" href="#contact"> CONTACT </a></li>
                        </ul>
                    </div>
                </div>
                </nav>     
            </div>
        )
    }
}

export default Navbar



 
