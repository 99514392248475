// This is the About Me section

import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <div>
        <section id='about'>
          <div id='aboutAnchor'></div>{' '}
          {/* Use to anchor AOS animations below */}
          <div id='about-section'>
            <div className='row'>
              <div
                className='col-sm-6'
                id='about-text'
                data-aos='fade-right'
                data-aos-anchor='#aboutAnchor'
                data-aos-anchor-placement='center-bottom'
              >
                <h2 id='sectionTitle'> ABOUT </h2>
                <br />
                <p>
                  {' '}
                  JZG is headquartered in San Francisco, CA. Our belief is that
                  in order to build a stronger community -- both locally and
                  globally -- we need to invest in diversity through elevating
                  businesses owned by minorities.{' '}
                </p>
              </div>
              <div
                className='col'
                id='about-image'
                data-aos='fade-left'
                data-aos-anchor='#aboutAnchor'
                data-aos-anchor-placement='center-bottom'
              >
                <img
                  src='img/jzg_logo_transparent_background_white_outline.png'
                  alt='Jiaozi Group Logo'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default About;
