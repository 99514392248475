// This is the HEADING/LANDING SCREEN

import React, { Component } from 'react';

export class LandingScreen extends Component {
  render() {
    return (
      <div>
        <section>
          <div id='home'>
            {' '}
            {/*This is the landing area, the overarching flexbox */}
            <div id='landingAnchor'>
              <div
                id='landingScreen-contentWrapper'
                data-aos='fade-down'
                data-aos-delay='200'
                data-aos-duration='2000'
                data-aos-anchor='#landingAnchor'
                data-aos-anchor-placement='top-center'
              >
                {' '}
                {/* This is the content div, which will allow us to move all content within flexbox as one */}
                <br />
                <br />
                <h1 id='tagline'> BUILD A BETTER TOMORROW</h1>
              </div>
              <div
                id='landingScreen-contentWrapper'
                data-aos='fade-up'
                data-aos-delay='1200'
                data-aos-duration='2000'
                data-aos-anchor='#landingAnchor'
                data-aos-anchor-placement='top-center'
              >
                <h4 id='tagline'>
                  BY INVESTING IN MINORITY-OWNED BUSINESSES TODAY
                </h4>
              </div>
              <div
                id='landingScreen-contentWrapper'
                data-aos='zoom-in'
                data-aos-delay='2100'
                data-aos-duration='2000'
                data-aos-anchor='#landingAnchor'
                data-aos-anchor-placement='top-center'
              >
                <a href='#contact' style={{ textDecoration: 'none' }}>
                  <button id='homeButton'>CONTACT US</button>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default LandingScreen;
