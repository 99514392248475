import React from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import { formikSchema } from '../schemas';

// https://www.youtube.com/watch?v=7Ophfq0lEAY
const onSubmit = async (values, actions) => {
  const newConnection = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    comments: values.comments,
    date: new Date(),
  };

  // make post request and handle response
  axios
    .post('https://jiaozigroup.herokuapp.com/create', newConnection)
    .then((response) => {
      const message = response.data.message;
      window.alert(message);
    })
    .catch((error) => {
      window.alert(error.response.data.error);
    });

  await new Promise((resolve) => setTimeout(resolve, 1000));
  actions.resetForm();
};

const Contact = () => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      comments: '',
    },
    validationSchema: formikSchema,
    onSubmit,
  });

  return (
    <div>
      <section id='contact'>
        <div className='col-12'>
          <h3 id='sectionSubtitle'> Interested in working together? </h3>
          <h2 id='sectionTitle' style={{paddingTop: '1rem'}}> GET IN TOUCH </h2>
        </div>
        <form onSubmit={handleSubmit} autoComplete='off'>
          <label htmlFor='firstName'></label>
          <input
            id='firstName'
            type='text'
            placeholder='First Name'
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.firstName && touched.firstName ? 'input-error' : ''
            }
          />
          {errors.firstName && touched.firstName && (
            <p className='error'>{errors.firstName}</p>
          )}
          <label htmlFor='lastName'></label>
          <input
            id='lastName'
            type='text'
            placeholder='Last Name'
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.lastName && touched.lastName ? 'input-error' : ''}
          />
          {errors.lastName && touched.lastName && (
            <p className='error'>{errors.lastName}</p>
          )}

          <label htmlFor='email'></label>
          <input
            id='email'
            type='email'
            placeholder='Email'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.email && touched.email ? 'input-error' : ''}
          />
          {errors.email && touched.email && (
            <p className='error'>{errors.email}</p>
          )}

          <label htmlFor='comments'></label>
          <textarea
            id='comments'
            type='text'
            placeholder='Comments'
            value={values.comments}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.comments && touched.comments ? 'input-error' : ''}
           > </textarea>
          {errors.comments && touched.comments && (
            <p className='error'>{errors.comments}</p>
          )}

          <button disabled={isSubmitting} type='submit'>
            Submit
          </button>
        </form>
      </section>
    </div>
  );
};

export default Contact;
