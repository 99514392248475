// This is the Skills section - can add state and map through

import React, { Component } from 'react';

export class Mission extends Component {
  render() {
    return (
      <div>
        <section id='invest'>
          {' '}
          {/* This id allows for quick navigation to this section from Navbar */}
          <div>
            <h2 id='sectionTitle'> INVESTMENT MINDSET </h2>
          </div>
          <div>
            {' '}
            {/* id's (like this one) or class's can be used to define design specs in CSS file, and "call" it in JS files  */}
            <div className='row'>
              <div className='col' id='mission-section'>
                <p>
                  {' '}
                  The diversity in our society offers a richness and variety of
                  ideas, cultures, and contributions, leading to next-level
                  innovation and creativity. That’s why JZG's mission is to
                  accelerate minority entrepreneurship to build a better
                  tomorrow for all. We do this through investing financial and
                  social capital in minority-owned businesses.{' '}
                </p>
                <p>--</p>
                <p>
                  {' '}
                  JZG works with organizations of all sizes, from start-ups to
                  large-scale fast-growth companies. We support them throughout
                  their entire journey, from the inception of ideas to
                  successful execution, and every milestone in between.{' '}
                </p>
                <p>--</p>

                <p>
                  {' '}
                  Representative investments include home styling services,
                  mental health technologies, and data science consulting.                {' '}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Mission;
