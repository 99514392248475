import React from 'react'

function Space() {
    return (
        <div>
            <section className="space">
                    <div>
                    </div>
            </section>
        </div>
    )
}

export default Space;